<template>
  <!-- <div class="settings-icon-component"> -->
  <!-- 设置图标 -->
  <svg-icon
    class="setting-icon"
    name="icon-screw"
    size="2.6rem"
    @click="settingsInfo.visible = true"
  />

  <!-- 设置操作 -->
  <van-action-sheet
    v-model:show="settingsInfo.visible"
    :actions="actionOptions"
    :close-on-click-action="true"
    cancel-text="取 消"
    @select="onSettingSelect"
    @cancel="settingsInfo.visible = false"
  />
  <!-- </div> -->
</template>

<script>
import {
  computed,
  defineComponent,
  reactive
} from 'vue'
import { ActionSheet, Dialog, Notify } from 'vant'
import SvgIcon from '@/components/svg-icon'
import { ToggleFullScreen } from '@/utils/index'

export default defineComponent({
  name: 'settings-icon',

  components: {
    SvgIcon,
    [ActionSheet.name]: ActionSheet
  },

  setup (props, context) {
    const state = reactive({})
    const settingsInfo = reactive({
      visible: false
    })

    const actionOptions = computed(() => {
      return [
        { name: '切换全屏', action: fullScreen },
        { name: '清除缓存', action: clearStore }
      ]
    })

    // 设置选中项
    function onSettingSelect (selectItem) {
      selectItem && selectItem.action && selectItem.action()
    }

    // 全屏使用
    function fullScreen () {
      ToggleFullScreen()
    }

    // 清除缓存信息
    function clearStore () {
      Dialog.confirm({
        message: '本地音乐列表信息将丢失\n重置为默认列表, 可将本地音乐列表下载'
      }).then(() => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        Notify({
          type: 'success',
          message: '缓存已清除',
          duration: 1000,
          onClose: () => {
            location.reload()
          }
        })
      })
    }

    return {
      state,
      settingsInfo,
      actionOptions,
      onSettingSelect
    }
  }
})
</script>
<style lang="scss" scoped>
.setting-icon {
  position: fixed;
  top: 1.8rem;
  right: 1.8rem;
  z-index: 10;
  color: rgba(255, 255, 255, 0.8);
}
</style>
