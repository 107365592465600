<template>
  <div class="lyric-view-component">
    <div class="lyric-inner" :style="`transform: translate3d(0, -${2 * (activeIndex - 1)}rem, 0);`">
      <div
        class="lyric-text"
        :class="{ 'lyric-active': activeIndex === index }"
        v-for="(lyric, index) in lyricList"
        :key="index"
      >
        {{ lyric.text }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'lyric-view',

  props: {
    lyric: String,
    currentTime: Number
  },

  setup (props, context) {
    const state = reactive({})
    // 歌词显示行列表
    const lyricList = computed(() => {
      let list = []
      if (!props.lyric) {
        list = [{ text: '暂无歌词', time: 0 }]
      } else {
        try {
          let lyric = props.lyric
          let offset = -1
          // 歌词补偿值, 毫秒 [offset:00]
          const offsetReg = /(\[offset:(-?\d{1,})\])/g
          const offsetItem = lyric.match(offsetReg)
          if (offsetItem && offsetItem.length) {
            const offsetNum = offsetItem[0].replace(offsetReg, '$2')
            console.log('offsetNum==>', offsetNum);
            offset = offset + Number(offsetNum)
          }
          lyric = lyric.replace(/\s+/g, ' ')
          lyric = lyric.replace(/\\n/g, '')
          lyric = lyric.replace(offsetReg, '')
          lyric = lyric.replace(/(\n|\r|(\r\n)|(\u0085)|(\u2028)|(\u2029))/g, "")
          lyric = lyric.replace(/\[/g, '\n[')
          let lyricMatchs = lyric.split('\n')
          lyricMatchs.splice(0, 1)
          lyricMatchs = lyricMatchs.map(item => {
            if (item.match(/^\[(ti):(.*)\]$/g)) {
              return item.replace(/^\[(ti):(.*)\]$/g, '[00:00.10]$2')
            } else if (item.match(/^\[(ar):(.*)\]$/g)) {
              return item.replace(/^\[(ar):(.*)\]$/g, '[00:00.20]作曲：$2')
            } else if (item.match(/^\[(al):(.*)\]$/g)) {
              return item.replace(/^\[(al):(.*)\]$/g, '[00:00.30]专辑：$2')
            } else if (item.match(/^\[(by):(.*)\]$/g)) {
              return item.replace(/^\[(by):(.*)\]$/g, '[00:00.40]作词：$2')
            } else {
              return item
            }
          })

          list = lyricMatchs.map((l) => {
            const timeTextList = l.match(/(\[\d{2}:\d{2}((\.|\:)\d{2,})\])/g)
            const timeStr = timeTextList && timeTextList.length ? timeTextList[0] : '[00:00]'
            const textSplit = l.split(timeStr)
            const text = textSplit.length > 1 ? textSplit[1] : ''
            const timeList = timeStr.replace(/(\[)|(\])/g, '').split(':')
            const time = timeList.length > 1 ? (Number(timeList[0]) * 60 + Number(timeList[1])).toFixed(2) : 0
            return {
              text,
              offset,
              time: Number(time) + Number(offset)
            }
          }).filter((item) => item.text.trim())
        } catch (error) {
          console.log('lyric-error =>', error);
          list = [{ text: '歌词加载失败', time: 0 }]
        }
      }
      console.log('lyricMatchs=>', list);
      return [{ text: '', time: 0 }, ...list, { text: '', time: 0 }]
    })

    // 歌词激活行坐标计算
    const activeIndex = computed(() => {
      let index = 0
      for (let i = 0; i < lyricList.value.length; i++) {
        const item = lyricList.value[i]
        index = i
        if (props.currentTime <= item.time) {
          break
        }
      }
      return index - 1 < 0 ? 0 : index - 1
    })

    return {
      state,
      lyricList,
      activeIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.lyric-view-component {
  height: 7rem;
  overflow: hidden;

  .lyric-inner {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s linear;

    .lyric-text {
      max-width: 80%;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.2rem;
      font-family: Helvetica, sans-serif;
      height: 2rem;
      line-height: 2rem;
      letter-spacing: 1px;
      transform: scale3d(0.8, 0.8, 0.8);
      transition: all 0.3s ease-in-out;

      &.lyric-active {
        color: var(--irc-text-color);
        transform: scale3d(1.2, 1.2, 1.2);
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
