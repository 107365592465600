<template>
  <div class="controls-bar-component">
    <svg-icon
      class="svg-icon"
      v-for="icon in iconList"
      :key="icon.name"
      :name="icon.name"
      :size="icon.size"
      @click.stop="iconEvent(icon)"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive
} from 'vue'
import SvgIcon from '@/components/svg-icon'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'controls-bar',

  components: {
    SvgIcon
  },

  setup (props, context) {
    const state = reactive({})
    const store = useStore()
    const playStatus = computed(() => {
      return store.getters.playStatus
    })

    const iconList = computed(() => {
      return [
        { name: 'icon-pre', actions: preMusic, size: '4rem' },
        { name: playStatus.value === '1' ? 'icon-stop' : 'icon-play', actions: playMusic, size: '5.8rem' },
        { name: 'icon-next', actions: nextMusic, size: '4rem' }
      ]
    })

    // 上一首
    function preMusic () {
      console.log('上一首音乐');
      store.dispatch('toggleMusic', { isNext: false })
    }

    // 播放音乐
    function playMusic () {
      console.log('播放/暂停音乐');
      store.dispatch('playMusic')
    }

    // 下一首
    async function nextMusic () {
      console.log('下一首音乐');
      await store.dispatch('toggleMusic', { isNext: true })
    }

    // icon 点击事件
    function iconEvent (iconItem) {
      iconItem.actions && iconItem.actions()
    }

    return {
      state,
      iconList,
      iconEvent
    }
  }
})
</script>
<style lang="scss" scoped>
.controls-bar-component {
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-icon {
    margin: 0 1.8rem;
  }
}
</style>
