<template>
  <div class="listen">
    <!-- blur背景 -->
    <div class="blur-bg"></div>

    <!-- 设置图标功能 -->
    <settings-icon />

    <!-- 结构-上部分 -->
    <div class="listen-top">
      <!-- 歌曲名称, 作者 -->
      <div class="music-head">
        <span class="name ellipsis-80p">{{ currentSource.title || '无标题' }}</span>
        <span class="author ellipsis-80p">{{ currentSource.author || '无作者' }}</span>
      </div>

      <!-- 唱片机 -->
      <div class="audio-box">
        <div class="inner-cover">
          <img
            v-if="currentSource.picUrl"
            key="normal-img"
            class="cover-img"
            :class="{ 'is-rote': playStatus === '1' }"
            :src="currentSource.picUrl"
            @error="currentSource.picUrl = null"
          />
          <svg-icon v-else class="svg-icon" name="icon-a-duomeitiyinle" size="100%" />
        </div>
      </div>

      <!-- 歌词展示部分 -->
      <div class="lyric-box">
        <lyric-view :lyric="currentSource.lyric" :currentTime="startTime" />
      </div>
    </div>

    <!-- 结构-下部分 -->
    <div class="listen-bottom">
      <!-- 功能跳转按钮区 -->
      <div class="operate-box">
        <function-icon />
      </div>

      <!-- 进度条部分 -->
      <div class="process-box">
        <process-bar />
      </div>

      <!-- 切换, 暂停, 播放区域 -->
      <div class="controls-box">
        <controls-bar />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  onMounted
} from 'vue'
import SettingsIcon from '@/components/settings-icon'
import SvgIcon from '@/components/svg-icon'
import ProcessBar from '@/components/process-bar'
import ControlsBar from '@/components/controls-bar'
import LyricView from '@/components/lyric-view'
import FunctionIcon from '@/components/function-icon'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Base64Decode } from '@/utils/index'
import { Dialog } from 'vant'

export default defineComponent({
  name: 'listen',

  components: {
    SettingsIcon,
    SvgIcon,
    ProcessBar,
    ControlsBar,
    LyricView,
    FunctionIcon
  },

  setup (props, context) {
    const state = reactive({})
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const currentSource = computed(() => store.getters.currentSource)
    const startTime = computed(() => store.getters.currentTime)
    const playStatus = computed(() => store.getters.playStatus)

    onMounted(() => {
      initQueryInfo()
    })

    // 检测连接中的地址信息
    async function initQueryInfo () {
      if (route.query.order) {
        const currentItem = await store.dispatch('getCurrentPlayListInfo', route.query.order)
        currentItem && await store.dispatch('togglePlayList', currentItem)
      }
      if (route.query.id) {
        const currentItem = await store.dispatch('getMusicInfoById', route.query.id)
        
        if (currentItem) {
          await store.dispatch('setCurrentSource', currentItem)
          router.replace(route.path)
        }
      }
    }

    return {
      state,
      currentSource,
      startTime,
      playStatus
    }
  }
})
</script>
<style lang="scss" scoped>
.listen {
  width: 100%;
  height: calc(100vh - env(SAFE-AREA-INSET-TOP, 0rem));
  overflow: hidden;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ellipsis-80p {
    max-width: 75%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blur-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    margin: -1rem;
    background-color: var(--music-background);
    filter: blur(0.5rem);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    z-index: -1;
  }

  .music-head {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    .name {
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }

    .author {
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }

  .audio-box {
    width: 70%;
    height: calc(100vw * 0.7);
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto 3rem;
    border-radius: 50%;
    background: var(--audio-background);
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-cover {
      width: 70%;
      height: 70%;
      border-radius: 50%;
      // background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .cover-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: cover-rote 20s linear infinite;
        animation-play-state: paused;

        &.is-rote {
          animation-play-state: running;
        }
      }
    }
  }

  .operate-box {
    margin-bottom: 3rem;
  }

  .process-box {
    margin-bottom: 3rem;
  }

  .controls-box {
    margin-bottom: 3rem;
  }
}
</style>
