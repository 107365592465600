<template>
  <div class="function-icon-component">
    <svg-icon
      class="svg-icon"
      v-for="icon in iconList"
      :key="icon.name"
      :name="icon.name"
      size="2.2rem"
      @click="icon.actions && icon.actions()"
    />

    <!-- 分享面板 -->
    <van-share-sheet
      v-model:show="shareInfo.shareVisable"
      :title="shareInfo.title"
      :options="shareInfo.options"
      @select="onShareSelect"
    />

    <!-- 当前播放列表 -->
    <van-action-sheet
      v-model:show="playInfo.visible"
      :title="`播放列表 - ${currentPlayListTitle}`"
      :closeable="false"
    >
      <div class="play-list-wrap">
        <play-list :visible="playInfo.visible" @close="playInfo.visible = false" />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  computed
} from 'vue'
import SvgIcon from '@/components/svg-icon'
import PlayList from '@/components/play-list'
import { ShareSheet, ActionSheet } from 'vant'
import { PLAY_LIST_TYPE_ICON_MAP } from '@/utils/const'
import { CopyText, Base64Encode } from '@/utils/index'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'function-icon',

  components: {
    SvgIcon,
    PlayList,
    [ShareSheet.name]: ShareSheet,
    [ActionSheet.name]: ActionSheet
  },

  setup (props, context) {
    const store = useStore()
    const router = useRouter()
    const currentSource = computed(() => store.getters.currentSource)
    const shareInfo = reactive({
      shareVisable: false,
      title: '立即分享给好友',
      options: [
        // { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link', action: copyLink },
        // { name: '分享海报', icon: 'poster' },
        // { name: '二维码', icon: 'qrcode' },
      ]
    })

    const iconList = computed(() => {
      return [
        { name: PLAY_LIST_TYPE_ICON_MAP[store.getters.playListType].className, actions: toggleSort },
        { name: 'icon-music-list', actions: musicOrders },
        { name: 'icon-share', actions: shareFriend },
        { name: 'icon-circular-help', actions: helpMe }
      ]
    })

    const playInfo = reactive({
      visible: false
    })

    // 当前歌单名称
    const currentPlayListTitle = computed(() => {
      const currentItem = store.dispatch('getCurrentPlayListInfo')
      return currentItem && currentItem.title || '默认列表'
    })

    // 切换播放模式, random-随机 inOrder-顺序 singleCycle-单曲循环
    function toggleSort () {
      store.dispatch('togglePlayListType')
    }

    // 打开歌单列表信息
    function musicOrders () {
      playInfo.visible = true
    }

    // 分享给朋友
    function shareFriend () {
      shareInfo.shareVisable = true
    }

    // 帮助信息提示等
    function helpMe () {
      router.push({ name: 'help-me' })
    }

    // 分享项选中
    function onShareSelect (option) {
      option && option.action && option.action()
      shareInfo.shareVisable = false
    }

    // 复制链接地址
    function copyLink () {
      const headText = `[烟花] 随辛听 - 你的音乐管家\n`
      const musicTilteText = currentSource.value?.title ? `[烟花] 歌曲: ${currentSource.value.title}\n` : ''
      const authorText = currentSource.value?.author ? `[烟花] 歌手: ${currentSource.value.author}\n` : ''
      // const { title, author, audioUrl } = currentSource.value
      // const info = Base64Encode(JSON.stringify({
      //   title,
      //   author,
      //   audioUrl
      // }))
      // const urlText = `[爱心] 链接地址: ${location.origin}?info=${info}`
      const urlText = `[烟花] 链接地址: ${location.origin}/?id=${currentSource.value.id}&order=${store.getters.currentPlayListName}`
      CopyText(`${headText}${musicTilteText}${authorText}${urlText}`)
    }

    return {
      shareInfo,
      iconList,
      playInfo,
      currentPlayListTitle,
      onShareSelect
    }
  }
})
</script>
<style lang="scss" scoped>
.function-icon-component {
  display: flex;
  justify-content: center;
  align-items: center;
  color: initial;

  .svg-icon {
    color: #fff;
    margin: 0 1rem;
  }
}
</style>
