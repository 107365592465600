<template>
  <div class="play-list-component">
    <!-- 操作图标 -->
    <div class="top-icon">
      <svg-icon
        class="svg-icon"
        v-for="icon in iconList"
        :key="icon.name"
        :name="icon.name"
        size="2.2rem"
        @click="iconActions(icon)"
      />
    </div>

    <!-- 搜索框 -->
    <van-search
      v-model="state.searchWord"
      placeholder="请输入搜索关键词"
      @update:model-value="filterSearch"
    />

    <!-- 列表展示 -->
    <div ref="listBoxRef" class="list-box">
      <div
        class="play-item"
        :class="{
          active: item.id === currentSource.id,
          error: item.loadError
        }"
        v-for="(item, index) in state.renderList"
        :key="index"
        :ref="(el) => (state.playItemRefs[index] = el)"
        @click.stop="playCurrent(item)"
      >
        <div class="name-author">
          <span class="name"
            >{{ item.title }}
            <span v-if="item.author" class="author">- {{ item.author }}</span>
          </span>
        </div>

        <div class="icons-box">
          <svg-icon
            v-for="(icon, index) in musicItemIcons"
            :key="index"
            class="close-icon"
            :name="icon.name"
            size="2rem"
            @click.stop="icon.actions && icon.actions(item)"
          />
        </div>
      </div>
    </div>

    <!-- 切换歌单action -->
    <van-action-sheet
      v-model:show="togglePlayListInfo.visible"
      :actions="actionOptions"
      :close-on-click-action="true"
      cancel-text="取 消"
      @select="onListSelect"
      @cancel="togglePlayListInfo.visible = false"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { ActionSheet, Notify, Search } from 'vant'
import SvgIcon from '@/components/svg-icon'
import { useRouter } from 'vue-router'
import { ToggleFullScreen, SaveFile } from '@/utils/index'


export default defineComponent({
  name: 'play-list',

  components: {
    SvgIcon,
    [ActionSheet.name]: ActionSheet,
    [Search.name]: Search
  },

  props: {
    visible: Boolean
  },

  setup (props, context) {
    const listBoxRef = ref(null)
    const state = reactive({
      playItemRefs: [],
      renderList: [],
      searchWord: ""
    })
    const store = useStore()
    const router = useRouter()

    const playList = computed(() => store.getters.musicList)
    const currentSource = computed(() => store.getters.currentSource)
    const iconList = computed(() => {
      return [
        { name: 'icon-folder-add', actions: addPlayList },
        { name: 'icon-debug', actions: togglePlayList },
        { name: 'icon-cloud-up', actions: uploadPlayList },
        { name: 'icon-cloud-down', actions: downloadPlayList },
        { name: 'icon-delete', actions: clearPlayList }
      ]
    })

    const musicItemIcons = [
      { name: 'icon-wacom', actions: editMusicItem },
      // { name: 'icon-circular-direction-down', actions: getDownloadUrl },
      { name: 'icon-close', actions: removeMusic }
    ]

    const togglePlayListInfo = reactive({
      visible: false
    })

    const actionOptions = computed(() => store.getters.playListFiles)

    // 初始化渲染列表
    state.renderList = [...playList.value]

    onMounted(() => {
      listScrollIntoView()
    })

    watch(() => props.visible, (newVal) => {
      if (newVal) {
        listScrollIntoView()
      }
    })

    // 歌单选中切换
    function onListSelect (selectItem) {
      store.dispatch('togglePlayList', selectItem)
    }

    // 点击播放当前音乐
    function playCurrent (item) {
      if (currentSource.value.id !== item.id) {
        // 搜索完成, 重置初始列表
        state.searchWord = ""
        state.renderList = [...playList.value]
        store.dispatch('setCurrentSource', item)
        store.dispatch('playMusic', '1')
        context.emit('close')
      }
    }

    // 搜索确认筛选列表处理
    function filterSearch () {
      state.renderList = playList.value.filter(m => {
        const title = m.title.toLocaleLowerCase()
        const search = state.searchWord.toLocaleLowerCase()
        return title.includes(search)
      })
    }

    // 图标列表点击触发事件
    function iconActions (iconItem) {
      ToggleFullScreen(true)
      iconItem.actions && iconItem.actions()
    }

    // 添加单个音源配置
    function addPlayList () {
      router.push({ name: 'add-music' })
    }

    // 切换歌单
    function togglePlayList () {
      togglePlayListInfo.visible = true
    }

    // 上传音源配置列表
    function uploadPlayList () {
      router.push({ name: 'import-music' })
    }

    // 下载音源配置列表
    function downloadPlayList () {
      const musicListJson = JSON.parse(JSON.stringify(store.getters.musicList))
      const jsonList = musicListJson.map((item) => {
        delete item.id
        return item
      })
      SaveFile(JSON.stringify(jsonList, null, 2), `music-${store.getters.currentPlayListName}.json`)
      context.emit('close')
    }

    // 清空音源配置列表
    async function clearPlayList () {
      await store.dispatch('clearMusicList')
      context.emit('close')
    }

    // 编辑歌曲信息
    function editMusicItem (item) {
      router.push({ name: 'add-music', query: { id: item.id } })
    }

    // 获取歌曲下载链接
    function getDownloadUrl (item) {
      window.open(item.audioUrl)
    }

    // 移除列表单个歌曲
    function removeMusic (item) {
      store.dispatch('removeMusicList', item)
    }

    // 打开列表时自动定位到当前位置
    function listScrollIntoView () {
      nextTick(() => {
        const currentIndex = state.renderList.findIndex((item) => item.id === currentSource.value.id)
        if (currentIndex > -1) {
          const currentScrollTop = state.playItemRefs[currentIndex] && state.playItemRefs[currentIndex].offsetTop - 80
          listBoxRef.value && (listBoxRef.value.scrollTop = currentScrollTop)
        }
      })
    }

    return {
      listBoxRef,
      state,
      playList,
      iconList,
      currentSource,
      musicItemIcons,
      togglePlayListInfo,
      actionOptions,
      filterSearch,
      onListSelect,
      playCurrent,
      iconActions,
      removeMusic
    }
  }
})
</script>
<style lang="scss" scoped>
.play-list-component {
  position: relative;

  .top-icon {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
    padding: 0 1rem 1rem;
    background: #fff;

    .svg-icon {
      margin: 0 1.5rem;
    }
  }

  .list-box {
    padding: 0.4rem 2rem 1rem;
    height: 52vh;
    overflow: auto;

    .play-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 0;
      letter-spacing: 0.1rem;

      &.error {
        color: var(--danger-color);

        .name-author {
          .name,
          .name .author {
            color: var(--danger-color);
          }
        }
      }

      &.active {
        color: var(--primary-color);

        .name-author {
          .name,
          .name .author {
            color: var(--primary-color);
          }
        }
      }

      .name-author {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 1);

        .name {
          max-width: 24rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.4rem;

          .author {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.6);
            margin-left: 0.2rem;
          }
        }
      }

      .icons-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .close-icon {
          font-size: 1.4rem;
          color: rgba(0, 0, 0, 0.4);
          margin-left: 1rem;
        }
      }
    }
  }
}
</style>
