<template>
  <svg class="music-icon" :style="`width:${size};height:${size};`" aria-hidden="true">
    <use :xlink:href="`#${name}`"></use>
  </svg>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'svg-icon',

  props: {
    name: String,
    size: {
      type: String,
      default: '2rem'
    }
  },

  setup (props, context) {
    const state = reactive({})

    return {
      state
    }
  }
})
</script>
