<template>
  <div class="process-bar-component">
    <span class="start">{{ startTime }}</span>
    <div class="bar-wrap">
      <div class="bar">
        <!-- <div class="storage" :style="`width:${bufferPercent}%;`"></div> -->
        <div class="current" :style="`transform:translateX(${currentPercent}%);`">
          <div class="dot"></div>
        </div>
      </div>
    </div>
    <span class="end">{{ allTime }}</span>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive
} from 'vue'
import { useStore } from 'vuex'
import { secondsFormat } from '@/utils/index'

export default defineComponent({
  name: 'process-bar',

  setup (props, context) {
    const state = reactive({})
    const store = useStore()
    const startTime = computed(() => secondsFormat(store.getters.currentTime))

    const allTime = computed(() => secondsFormat(store.getters.allTime))

    // 缓存长度
    // const bufferPercent = computed(() => {
    //   console.log('bufferTime===>', store.getters.allTime, store.getters.bufferTime);
    //   const bufferTime = store.getters.bufferTime
    //   const allTime = store.getters.allTime
    //   return bufferTime ? allTime * 100 / bufferTime : 0
    // })

    // 当前播放长度
    const currentPercent = computed(() => {
      // console.log('currentPercent===>', store.getters.currentTime, store.getters.allTime);
      const currentTime = store.getters.currentTime
      const allTime = store.getters.allTime
      return allTime ? currentTime * 100 / allTime : 0
    })

    return {
      state,
      startTime,
      allTime,
      // bufferPercent,
      currentPercent
    }
  }
})
</script>
<style lang="scss" scoped>
.process-bar-component {
  display: flex;
  justify-content: center;
  align-items: center;

  .start,
  .end {
    width: 14%;
  }

  .start {
    text-align: right;
  }

  .end {
    text-align: left;
  }

  .bar-wrap {
    overflow: hidden;
    // width: 62%;
    flex: 1;
    height: 1.2rem;
    margin: 0 1.4rem;
    padding: 0 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bar {
    position: relative;
    flex: 1;
    height: 0.2rem;
    background: var(--process-background);

    .storage {
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 100%;
      background: var(--process-storage-background);
    }

    .current {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: var(--process-current-background);
      transform: translateX(0);
      transition: transform 0.02s;

      .dot {
        position: absolute;
        top: 0;
        right: -0.3rem;
        bottom: 0;
        margin: auto 0;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background: var(--process-dot-background);
        transition: transform 0.1s;

        &:hover {
          transform: scale3d(1.6, 1.6, 1.6);
        }
      }
    }
  }
}
</style>
